/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
https://css-tricks.com/responsive-data-tables/
*/
@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    /* Force table to not be like tables anymore */
    .trainingstabelle thead, tbody, th, td, tr {
        display: block;
        empty-cells: hide;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .trainingstabelle tr  th {
        display: none;
    }

    .trainingstabelle tr { border: 1px solid #ccc; }

    .trainingstabelle td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    .trainingstabelle td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    /*
    Label the data
    */
    .trainingstabelle td:nth-of-type(1):before { content: "Datum"; }
    .trainingstabelle td:nth-of-type(2):before { content: "Beschreibung"; }
    .trainingstabelle td:nth-of-type(3):before { content: "Statistik 'nein'"; }
    .trainingstabelle td:nth-of-type(4):before { content: "'first to legs'"; }
    .trainingstabelle td:nth-of-type(5):before { content: "Abgeschlossen"; }
    .trainingstabelle td:nth-of-type(6):before { content: ""; }
}
